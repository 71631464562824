<template>
  <v-container fluid>
    <Loader v-if="loading" :height="`500px`"/>
    <v-row v-else dense class="mb-4 mx-1">
      <v-col cols="12" md="8" lg="9">
        <v-row justify="center" class="pt-0 pb-0">
          <v-col cols="12">
            <v-row dense align="center">
              <v-col
                cols="12"
                md="6"
                lg="5"
                class="adjust-center headline text-sm-left"
              >
                <img
                  v-if="currency.logo === null"
                  :alt="currency.name"
                  :src="require(`@/assets/images/no-image.png`)"
                  class="currency-logo"
                />
                <img
                  v-else
                  :alt="currency.name"
                  :src="currency.logo"
                  :lazy-src="currency.logo"
                  class="ml-1 currency-logo"
                  @error="helper.imgerror"
                />
                <span class="mt-2 ml-1">{{ currency.name }}</span>
                <small class="mx-1 grey--text">{{ currency.symbol }}</small>

                <span class="float-right secondary--text f-20">
                  <v-icon small :class="`mt-m-1px icon-${selected_currency.toLowerCase()}`"/>
                  {{ helper.roundDecimalPlaces(currency.price) }}
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      icon
                      class="elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="isWatchlistOpened({
                        name: currency.name,
                        id: currency.bcm_coin_id,
                        watchlist: isInWatchlist
                      })"
                    >
                      <v-icon v-if="isInWatchlist" color="accent" class="icon-star" />
                      <v-icon v-else class="icon-star-outline" />
                    </v-btn>
                  </template>
                  <span class="body-2">
                    {{ isInWatchlist ? $t('RemoveToWatchlist', [currency.name]) : $t('AddToWatchlist', [currency.name]) }}
                  </span>
                </v-tooltip>

              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="7"
                class="text-center text-md-right"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currency.website"
                      icon
                      v-bind="attrs"
                      :href="currency.website"
                      target="_blank"
                      class="mr-1 elevation-0"
                      :alt="$t('Website')"
                      v-on="on"
                    >
                      <v-icon>mdi-web</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ currency.website }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currency.facebook"
                      icon
                      v-bind="attrs"
                      :href="currency.facebook"
                      target="_blank"
                      class="mr-1 elevation-0"
                      :alt="$t('Facebook')"
                      v-on="on"
                    >
                      <v-icon>mdi-facebook</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ currency.facebook }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currency.twitter"
                      icon
                      v-bind="attrs"
                      :href="currency.twitter"
                      target="_blank"
                      class="mr-1 elevation-0"
                      :alt="$t('Twitter')"
                      v-on="on"
                    >
                      <v-icon>mdi-twitter</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ currency.twitter }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currency.youtube"
                      icon
                      v-bind="attrs"
                      :href="currency.youtube"
                      target="_blank"
                      class="mr-1 elevation-0"
                      :alt="$t('Youtube')"
                      v-on="on"
                    >
                      <v-icon>mdi-youtube</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ currency.youtube }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currency.reddit"
                      icon
                      v-bind="attrs"
                      :href="currency.reddit"
                      target="_blank"
                      class="mr-1 elevation-0"
                      :alt="$t('Reddit')"
                      v-on="on"
                    >
                      <v-icon>mdi-reddit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ currency.reddit }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currency.github"
                      icon
                      v-bind="attrs"
                      :href="currency.github"
                      target="_blank"
                      class="mr-1 elevation-0"
                      :alt="$t('Github')"
                      v-on="on"
                    >
                      <v-icon>mdi-github</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ currency.github }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currency.message_board"
                      icon
                      v-bind="attrs"
                      :href="currency.message_board"
                      target="_blank"
                      class="mr-1 elevation-0"
                      :alt="$t('Board')"
                      v-on="on"
                    >
                      <v-icon>mdi-message-text-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ currency.message_board }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="currency.technical_doc"
                      icon
                      v-bind="attrs"
                      :href="currency.technical_doc"
                      target="_blank"
                      class="elevation-0"
                      :alt="$t('WhitePaper')"
                      v-on="on"
                    >
                      <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ currency.technical_doc }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col>
                <span
                  class="body-2"
                  v-html="currency.description1"
                ></span>
              </v-col>
            </v-row>
            <v-row dense class="d-flex d-md-none">
              <v-col cols="12" lg="4">
                <v-card outlined class="fill-height card_bg">
                  <v-row class="pa-2">
                    <v-col cols="12" >
                      <v-btn
                        class="elevation-0 uncapitalize primary float-right"
                        @click="onRateCoin({
                          name: currency.name,
                          id: currency.bcm_coin_id,
                        })"
                      >
                        <v-icon class="icon-rate body-2"/>
                        <span>{{ $t('RateCoin', [currency.symbol]) }}</span>
                      </v-btn>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left grey--text">{{ $t('userRating') }}</span>
                        <span class="float-right secondary--text">
                          <v-icon
                            v-for="index in 5"
                            :key="index"
                            :class="`
                              no-hover-bg
                              no-bg
                              ${ computeRateStar(index) }
                            `"
                          />
                        </span>
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left">{{ $t('Price') }}</span>
                        <span class="float-right secondary--text">
                          <v-icon x-small :class="`icon-${selected_currency.toLowerCase()}`"/>
                          {{ helper.roundDecimalPlaces(currency.price) }}
                        </span>
                      </p>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left">{{ $t('Rank') }}</span>
                        <span class="float-right secondary--text">#{{ currency.cmc_rank }}</span>
                      </p>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left">{{ $t('MarketCap') }}</span>
                        <span class="float-right secondary--text">{{ helper.convertToBigNumber(currency.market_cap , 2) }}</span>
                      </p>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left">{{ `${$t('24H')} ${$t('Volume')}` }}</span>
                        <span class="float-right secondary--text">{{ helper.convertToBigNumber(currency.volume_24h , 2) }}</span>
                      </p>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left">{{ `${$t('Circulating')} ${$t('Supply')}` }}</span>
                        <span class="float-right secondary--text">{{ helper.convertToBigNumber(currency.circulating_supply , 2) }}</span>
                      </p>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left grey--text">{{ `${$t('1H')} ${$t('Change')}` }}</span>
                        <span :class="`float-right ${ currency.percent_change_1h > 0 ? 'green--text': 'red--text' }`">
                          {{
                            currency.percent_change_1h > 0
                              ? helper.convertToBigNumber(
                                currency.percent_change_1h,
                                2
                              )
                              : helper.convertToBigNumber(
                                currency.percent_change_1h * -1,
                                2
                              )
                          }}%
                        </span>
                      </p>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left grey--text">{{ `${$t('24H')} ${$t('Change')}` }}</span>
                        <span :class="`float-right ${ currency.percent_change_24h > 0 ? 'green--text': 'red--text' }`">
                          {{
                            currency.percent_change_24h > 0
                              ? helper.convertToBigNumber(
                                currency.percent_change_24h,
                                2
                              )
                              : helper.convertToBigNumber(
                                currency.percent_change_24h * -1,
                                2
                              )
                          }}%
                        </span>
                      </p>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left grey--text">{{ `${$t('7D')} ${$t('Change')}` }}</span>
                        <span :class="`float-right ${ currency.percent_change_7d > 0 ? 'green--text': 'red--text' }`">
                          {{
                            currency.percent_change_7d > 0
                              ? helper.convertToBigNumber(
                                currency.percent_change_7d,
                                2
                              )
                              : helper.convertToBigNumber(
                                currency.percent_change_7d * -1,
                                2
                              )
                          }}%
                        </span>
                      </p>
                    </v-col>
                    <v-divider class="mx-2" />
                    <v-col cols="12">
                      <p class="detail">
                        <span class="float-left grey--text">{{ `${$t('30D')} ${$t('Change')}` }}</span>
                        <span :class="`float-right ${ currency.percent_change_30d > 0 ? 'green--text': 'red--text' }`">
                          {{
                            currency.percent_change_30d > 0
                              ? helper.convertToBigNumber(
                                currency.percent_change_30d,
                                2
                              )
                              : helper.convertToBigNumber(
                                currency.percent_change_30d * -1,
                                2
                              )
                          }}%
                        </span>
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-divider class="my-5" />
            <v-card class="elevation-0 pa-3 pa-md-5 card_bg">
              <v-row dense justify="center">
                <!-- CHART CONTROLS ----------------------------------- -->
                <v-col cols="12" class="text-right">
                  <v-row dense>
                    <v-col v-if="isBtnGroupVisible" cols="12" sm="6" md="6">
                      <v-btn-toggle
                        class="float-left toggle-controls v-btn-group v-btn-group--5 d-none d-lg-flex"
                        dense
                        mandatory
                      >
                        <v-btn
                          v-for="(btn, idx) in btnGroupDetails"
                          :key="idx"
                          :input-value="btn.selected"
                          :disabled="chartType < 1 ? false : true"
                          class="body-2 uncapitalize"
                          @click="onChangeViewIcons(idx)"
                        >
                          <span>{{ btn.name }}</span>
                        </v-btn>
                      </v-btn-toggle>

                      <Menu
                        v-if="isBtnGroupVisible"
                        :button-size="`primary no-hover-bg uncapitalize d-flex d-lg-none`"
                        :is-disabled="chartType < 1 ? false : true"
                        :selected-text="selected_tab.name"
                        :list-items="btnGroupDetails"
                        key-item="name"
                        @click="onChangeViewIconsMenu($event)"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn-toggle
                        class="float-left toggle-controls v-btn-group v-btn-group--3"
                        dense
                        mandatory
                      >
                        <v-btn
                          v-for="(btn, idx) in chartGroupType"
                          :key="idx"
                          :disabled="viewType < 4 ? false : true"
                          class="body-2 uncapitalize"
                          @click="onChangeChartType(btn.id)"
                        >
                          <span>{{ btn.name }}</span>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="12" :sm="isTableViewType?3:6" :md="isTableViewType?3:6">
                      <v-btn-toggle
                        class="float-right v-btn-group v-btn-group--4 toggle-controls d-none d-lg-flex"
                        dense
                        mandatory
                      >
                        <v-btn
                          v-for="btn in common.daysGroup"
                          :key="btn.name"
                          :input-value="btn.selected"
                          class="body-2 uncapitalize px-2"
                          @click="onChangeDays(btn)"
                        >
                          <span>{{ btn.name }}</span>
                        </v-btn>
                      </v-btn-toggle>

                      <Menu
                        :button-size="`primary no-hover-bg uncapitalize d-flex d-lg-none`"
                        :selected-text="selected_days.name"
                        :list-items="common.daysGroup"
                        key-item="name"
                        @click="onChangeDaysMenu($event)"
                      />
                    </v-col>
                    <v-col
                      v-if="isTableViewType"
                      cols="12"
                      sm="3"
                      md="3"
                    >
                      <v-row dense align="center">
                        <v-col cols="4" lg="4" class="text-right">
                          <small class="grey--text">{{ $t('Rows') }}</small>
                        </v-col>
                        <v-col cols="4">
                          <Menu
                            :selected-text="`${rowsPerPage.toString()}`"
                            :list-items="rows"
                            :button-size="`common_background`"
                            @click="onChangeRowsPerPage($event)"
                          />
                        </v-col>
                        <v-col cols="2" lg="2" >
                          <v-btn
                            block
                            :value="true"
                            class="float-right no-hover-bg"
                            :disabled="isPrevPaginateDisabled"
                            @click="onPreviousPage"
                          >
                            <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
                          </v-btn>
                        </v-col>
                        <v-col cols="2" lg="2">
                          <v-btn
                            block
                            :value="false"
                            class="float-right no-hover-bg"
                            :disabled="isNextPaginateDisabled"
                            @click="onNextPage"
                          >
                            <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="viewType < 4 && !isTableViewType" cols="12">
                  <Loader v-if="chart_loading" class="pt-16 pb-10" :height="'362px'" />
                  <template v-else>
                    <div v-if="isCustomLegendsVisible && chartType < 2" class="d-flex f-16 flex-wrap justify-center">
                      <v-checkbox
                        v-for="(legend, index) in legends"
                        :key="index"
                        v-model="selected_legends"
                        class="mx-2"
                        :color="legend.color"
                        multiple
                        :hide-details="index !== legends.length - 1"
                        :value="legend.name"
                        @change="onLegendChange($event, index)"
                      >
                        <template v-slot:label>
                          <div
                            class="custom-legend f-14"
                            :style="{ background: legend.color }"
                          >
                          </div>
                          <span class="mx-1 f-14">{{ legend.name }}</span>
                        </template>
                      </v-checkbox>
                    </div>
                    <SimpleChart v-if="chartType < 2" :is-multiaxis="true" :chart-data="simpleDataset" />
                    <template v-else>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <SimpleChart :chart-data="[priceParams()].flat()" />
                        </v-col>
                        <v-col cols="12" lg="6">
                          <SimpleChart :chart-data="[volumeParams()].flat()" />
                        </v-col>
                        <v-col cols="12" lg="6">
                          <SimpleChart :chart-data="[marketCapParams()].flat()" />
                        </v-col>
                        <v-col cols="12" lg="6">
                          <SimpleChart :chart-data="[activityParams()].flat()" />
                        </v-col>
                      </v-row>
                    </template>
                  </template>
                </v-col>
                <v-col
                  v-else
                  cols="12"
                  class="text-center"
                >
                  <Loader v-if="chart_loading" class="pt-16 pb-10" :height="'200px'" />
                  <v-card v-else outlined class="mt-2 card_bg">
                    <v-data-table
                      :items="history"
                      :loading="loading"
                      loading-text="Loading"
                      :headers="headers"
                      :headers-length="4"
                      :items-per-page="rowsPerPage"
                      :loader-height="1"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      no-data-text="No Record Found."
                      item-key="market_cap_rank"
                      class="border-box"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td class="text-left currency-details">
                            {{
                              `${item.load_date_time.slice(0,10)} ${item.load_date_time.slice(11, 19)}`
                            }}
                          </td>
                          <td class="text-right font-weight-bold">
                            <v-icon x-small :class="`grey--text icon-${selected_currency.toLowerCase()}`"/>
                            {{ helper.roundDecimalPlaces(item.price) }}
                          </td>
                          <td class="text-right">
                            {{ helper.convertToBigNumber(item.market_cap, 2) }}
                          </td>
                          <td class="text-right">
                            {{ helper.convertToBigNumber(item.volume_24h, 2) }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" lg="3" class="d-none d-md-flex">
        <v-card class="fill-height card_bg">
          <v-row class="pa-2">
            <v-col cols="12" >
              <v-btn
                class="elevation-0 uncapitalize primary float-right"
                @click="onRateCoin({
                  name: currency.name,
                  id: currency.bcm_coin_id,
                })"
              >
                <v-icon class="icon-rate body-2"/>
                <span>{{ $t('RateCoin', [currency.symbol]) }}</span>
              </v-btn>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ $t('userRating') }}</span>
                <span class="float-right secondary--text">
                  <v-icon
                    v-for="index in 5"
                    :key="index"
                    :class="`
                      no-hover-bg
                      no-bg
                      ${ computeRateStar(index) }
                    `"
                  />
                </span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ $t('Price') }}</span>
                <span class="float-right secondary--text">
                  <v-icon x-small :class="`icon-${selected_currency.toLowerCase()}`"/>
                  {{ helper.roundDecimalPlaces(currency.price) }}
                </span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ $t('Rank') }}</span>
                <span class="float-right secondary--text">#{{ currency.cmc_rank }}</span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ $t('MarketCap') }}</span>
                <span class="float-right secondary--text">{{ helper.convertToBigNumber(currency.market_cap , 2) }}</span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ `${$t('24H')} ${$t('Volume')}` }}</span>
                <span class="float-right secondary--text">{{ helper.convertToBigNumber(currency.volume_24h , 2) }}</span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ `${$t('Circulating')} ${$t('Supply')}` }}</span>
                <span class="float-right secondary--text">{{ helper.convertToBigNumber(currency.circulating_supply , 2) }}</span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ `${$t('1H')} ${$t('Change')}` }}</span>
                <span :class="`float-right ${ currency.percent_change_1h > 0 ? 'green--text': 'red--text' }`">
                  {{
                    currency.percent_change_1h > 0
                      ? helper.convertToBigNumber(
                        currency.percent_change_1h,
                        2
                      )
                      : helper.convertToBigNumber(
                        currency.percent_change_1h * -1,
                        2
                      )
                  }}%
                </span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ `${$t('24H')} ${$t('Change')}` }}</span>
                <span :class="`float-right ${ currency.percent_change_24h > 0 ? 'green--text': 'red--text' }`">
                  {{
                    currency.percent_change_24h > 0
                      ? helper.convertToBigNumber(
                        currency.percent_change_24h,
                        2
                      )
                      : helper.convertToBigNumber(
                        currency.percent_change_24h * -1,
                        2
                      )
                  }}%
                </span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ `${$t('7D')} ${$t('Change')}` }}</span>
                <span :class="`float-right ${ currency.percent_change_7d > 0 ? 'green--text': 'red--text' }`">
                  {{
                    currency.percent_change_7d > 0
                      ? helper.convertToBigNumber(
                        currency.percent_change_7d,
                        2
                      )
                      : helper.convertToBigNumber(
                        currency.percent_change_7d * -1,
                        2
                      )
                  }}%
                </span>
              </p>
            </v-col>
            <v-divider class="mx-2" />
            <v-col cols="12">
              <p class="detail">
                <span class="float-left grey--text">{{ `${$t('30D')} ${$t('Change')}` }}</span>
                <span :class="`float-right ${ currency.percent_change_30d > 0 ? 'green--text': 'red--text' }`">
                  {{
                    currency.percent_change_30d > 0
                      ? helper.convertToBigNumber(
                        currency.percent_change_30d,
                        2
                      )
                      : helper.convertToBigNumber(
                        currency.percent_change_30d * -1,
                        2
                      )
                  }}%
                </span>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <SigninDialog
      :dialog="dialog"
      :header-text="dialogHeaderText"
      :body-text="dialogBodyText"
      @close="dialog=false"
    />

    <CoinRate :dialog="rateDialog" :currency="rateCurrency" @close="rateDialog=false" @click="onSubmitRate"/>

    <SnackBar :snackbar="snackbar" :color="color" :error="error" @close="snackbar=false"/>
  </v-container>
</template>

<script>
import _ from 'lodash'
import {
  CURRENCY_REQUEST,
  CURRENCY_HISTORY_REQUEST,
  CURRENCY_ACTIVITY_REQUEST,
  USER_CURRENCY_RATINGS_REQUEST,
  POST_CURRENCY_RATINGS_REQUEST,
  CURRENCY_RATINGS_REQUEST,
} from '@/store/currency/action'
import {
  GET_WATCHLIST_IDS_REQUEST,
  POST_WATCHLIST_REQUEST,
  DELETE_WATCHLIST_REQUEST,
} from '@/store/overview/action'
import firebase from 'firebase'
import SimpleChart from '@/components/organisms/chart/Compare'
import Loader from '@/components/molecules/Loader'
import Menu from '@/components/atoms/Menu'
import SigninDialog from '@/components/shared/SigninDialog'
import CoinRate from '@/components/shared/CoinRate'
import SnackBar from '@/components/shared/SnackBar'
import helper from '@/utils/helper'
import { get, call, dispatch } from 'vuex-pathify'
import constants from '@/constants/constants'
import common from '@/shared/common'

export default {
  name:'CurrencyDetails',
  components: {
    Loader,
    SimpleChart,
    Menu,
    SigninDialog,
    SnackBar,
    CoinRate,
  },
  data: () => ({
    isPrevPaginateDisabled: false,
    isNextPaginateDisabled: false,
    next: true,
    prev: false,
    totalNumberOfItems: 0,
    rows: [10, 20, 50, 100],
    rowsPerPage: 10,
    page: 1,
    common: common,
    helper: helper,
    user: {},
    days: '1D',
    params: {},
    isTableFormat: false,
    labels: [],
    chartData: [],
    display: 'desc',
    loading: true,
    chart_loading: true,
    dialog: false,
    dialogHeaderText: '',
    dialogBodyText: '',
    rateDialog: false,
    snackbar: false,
    error: null,
    color: 'success',
    chartType: 1,
    viewType: 0,
    isTableViewType: false,
    simpleDataset: [],
    selected_tab: { name: 'Price', icon: 'mdi-currency-usd' },
    selected_chart_type: { name: 'Multi-Axis' },
    selected_days: { name: '1D' },
    selected_legends: ['Price'],
    isCustomLegendsVisible: true,
    sortBy: 'load_date_time',
    sortDesc: true,
    isBtnGroupVisible: false,
    isInWatchlist: false,
    rateCurrency: {},
    history: [],
    btnGroupDetails: [
      { name: 'Price', icon: 'mdi-currency-usd', selected: true },
      { name: 'Volume', icon: 'mdi-chart-box-outline', selected: false },
      { name: 'Market Cap', icon: 'mdi-chart-gantt', selected: false },
      { name: 'Activity', icon: 'mdi-chart-line', selected: false },
    ],
    chartGroupType: [
      { name: 'Multi-Axis', id: 1,  selected: true },
      { name: 'Multi-Chart', id: 2 , selected: false },
      { name: 'Table', selected: false, id: 3 },
    ],
    legends: [
      { name: 'Price', color: constants.chartAxisColors.primary },
      { name: 'Volume', color: constants.chartAxisColors.secondary_3 },
      { name: 'Market Cap', color: constants.chartAxisColors.secondary_1 },
      { name: 'Activity', color: constants.chartAxisColors.red },
    ],
    headers: [
      {
        text: 'Date',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Price',
        align: 'right',
        sortable: false,
      },
      {
        text: 'Market Cap',
        align: 'right',
        sortable: false,
      },
      {
        text: 'Volume',
        align: 'right',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...get('overview', ['watchlistIds']),
    currency: get('currency/getCurrency'),
    historyAll: get('currency/getHistory'),
    activity: get('currency/getActivity'),
    selected_currency: get('getGlobalFiat'),
    ratings: get('currency/getRatings'),
    average_rating: get('currency/getAverageRating'),
  },
  watch: {
    '$route.path': {
      handler: function (path) {
        this.getRouteParams()
        this.onFetchCurrency()
        this.onFetchHistory()
      },
      deep: true,
    },
    '$store.state.fiat': {
      handler(value) {
        this.onFetchCurrency()
        this.onFetchHistory()
      },
      deep: true,
    },
  },
  async mounted() {
    this.user = firebase.auth().currentUser

    if (this.user !== null && this.user.emailVerified) {
      await this.GET_WATCHLIST_IDS_REQUEST()
      this.checkCurrencyWatchlist()
      await this.onFetchRating()
    }

    await this.getRouteParams()
    this.checkCurrencyWatchlist()
    await this.onFetchCurrency()
    await this.CURRENCY_RATINGS_REQUEST({ coin_id: this.$route.params.id })
    await this.onFetchHistory()
  },
  methods: {
    ...call('currency', [
      'CURRENCY_REQUEST',
      'CURRENCY_HISTORY_REQUEST',
      'CURRENCY_ACTIVITY_REQUEST',
      'USER_CURRENCY_RATINGS_REQUEST',
      'CURRENCY_RATINGS_REQUEST',
    ]),
    ...call('overview', ['GET_WATCHLIST_IDS_REQUEST']),
    computeRateStar(index) {
      return this.average_rating - Math.floor(this.average_rating) >= 0.5 &&
        index === Math.round(this.average_rating) ? 'icon-rate accent--text' :
        index <= this.average_rating ? 'icon-star accent--text' : 'icon-star-outline'
    },
    async onSubmitRate(val) {

      const payload = {
        ...val,
        coin_id: this.currency.bcm_coin_id,
      }

      await dispatch('currency/' + POST_CURRENCY_RATINGS_REQUEST, payload)
        .then(async (resp) => {
          this.snackbar = true
          if (resp.status_code !== 400) {
            this.rateCurrency = {
              ...val,
              bur_create_date: new Date().toISOString(),
            }

            await this.CURRENCY_RATINGS_REQUEST({ coin_id: this.$route.params.id })

            this.color = 'success'
            this.error = this.currency.name + ' rating successfully added '
          } else {
            this.color = 'error'
            this.error = this.currency.name + ': ' + resp.detail
          }
        })
        .catch((error) => {
          this.snackbar = true
          this.color = 'error'
          this.error = this.currency.name + ': ' + error.detail
        })
    },
    async onRateCoin(name, id) {
      const isWatchListOpened = localStorage.getItem('isWatchListOpened')

      if (isWatchListOpened === null && this.user === null) {
        this.dialogHeaderText = this.$t('RateCoin', [this.currency.name])
        this.dialogBodyText = this.$t('RatingInfo')
        this.dialog = true
      } else if (this.user !== null && this.user.email) {
        this.onSetRatingStars()
        this.rateDialog = true
      } else {
        this.$router.push({ path: '/account' })
      }
    },
    onSetRatingStars() {
      const tempRate = this.ratings.filter((o) => o.bur_coin_id === parseInt(this.$route.params.name))

      this.rateCurrency = tempRate.length > 0 ? {
        ...tempRate[tempRate.length - 1],
        technology: tempRate[tempRate.length - 1].quality,
        name: this.currency.name,
      } : {
        bur_create_date: null,
        rating: 0,
        cust_service: 0,
        ease_of_use: 0,
        progress: 0,
        usage_fee:0,
        technology: 0,
        name: this.currency.name,
      }
    },
    async onFetchRating() {
      await this.USER_CURRENCY_RATINGS_REQUEST({ coin_id: this.$route.params.id })
      this.onSetRatingStars()
    },
    async isWatchlistOpened({ name, id, watchlist }) {
      const isWatchListOpened = localStorage.getItem('isWatchListOpened')

      if (isWatchListOpened === null && this.user === null) {
        this.dialogHeaderText = this.$t('Watchlist')
        this.dialogBodyText = this.$t('WatchlistUpdateInfo')
        this.dialog = true
      } else if (this.user !== null && this.user.email) {
        if (!watchlist) {
          await dispatch('overview/' + POST_WATCHLIST_REQUEST, id)
            .then(async (resp) => {
              this.snackbar = true
              this.color = 'success'
              this.error = name + ' successfully added to watchlist'
              await this.getWatchListIds()
              this.checkCurrencyWatchlist()
            })
            .catch((error) => {
              this.snackbar = true
              this.color = 'error'
              this.error = name + ': ' + error.detail
            })
        } else {
          await dispatch('overview/' + DELETE_WATCHLIST_REQUEST, id)
            .then(async (resp) => {
              this.snackbar = true
              this.color = 'primary'
              this.error = name + ' successfully removed from watchlist'
              await this.getWatchListIds()
              this.checkCurrencyWatchlist()
            })
            .catch((error) => {
              this.snackbar = true
              this.color = 'error'
              this.error = name + ': ' + error.detail
            })
        }
      } else {
        this.$router.push({ path: '/account' })
      }
    },
    async getWatchListIds() {
      const currentUser = await firebase.auth().currentUser

      if (currentUser && currentUser.emailVerified) {
        await dispatch('overview/' + GET_WATCHLIST_IDS_REQUEST)
      }
    },
    checkCurrencyWatchlist() {
      const temp = this.watchlistIds.find((o) => o.buw_coin_id === this.$route.params.id )

      this.isInWatchlist = temp ? true : false
    },
    changeChartData() {
      this.isTableViewType = false
      this.simpleDataset = []
      if (this.chartType === 0) {
        if (this.viewType < 3) {
          this.simpleDataset.push({
            name: this.params.name,
            symbol: this.params.name,
            labels: this.params.label,
            data: this.viewType === 0 ? this.params.data : this.viewType === 1 ? this.params.volume : this.params.marketCap,
            id: 'bcm',
            simple: true,
            days: this.days,
          })
        } else {
          this.simpleDataset.push({
            name: this.params.name,
            symbol: this.params.name,
            labels: this.activity.label,
            data: this.activity.data,
            id: 'bcm',
            simple: true,
            days: this.days,
          })
        }
      } else if (this.chartType === 1) {
        this.simpleDataset.push(this.priceParams())
      } else if (this.chartType === 3) {
        this.isTableViewType = true
      }
    },
    getRouteParams() {
      this.$route.params.id =
        typeof this.$route.params.name === 'string'
          ? parseFloat(this.$route.params.name)
          : this.$route.params.id
    },
    async onChangeDaysMenu(item) {
      this.common.daysGroup.forEach((o) => {
        this.days =  o.name.toLowerCase() === item.name.toLowerCase() ? o.name : this.days
        o.selected = o.name === item.name ? true : false
      })

      this.selected_days = item
      await this.onFetchHistory()
    },
    async onChangeDays(val) {
      this.days = val.name

      this.common.daysGroup.forEach((o) => {
        o.selected = o.name === val.name ? true : false
      })

      this.selected_days = val
      await this.onFetchHistory()
    },
    onChangeChartType(type) {
      this.chartType = type
      this.changeChartData()
    },
    onChangeViewIconsMenu(item) {
      this.btnGroupDetails.forEach((o, index) => {
        if (o.name.toLowerCase() === item.name.toLowerCase()) {
          o.selected = true
          this.selected_tab = o
          this.viewType = index
        } else {
          o.selected = false
        }
      })

      this.changeChartData()
    },
    async onChangeViewIcons(val) {
      this.viewType = val

      this.btnGroupDetails.forEach((o, index) => {
        if (index === val) {
          o.selected = true
          this.selected_tab = o
        } else {
          o.selected = false
        }
      })

      this.changeChartData()
    },
    async onChangeView(val) {
      this.selected_tab = val
      this.viewType = val.index
      this.changeChartData()
    },
    priceParams() {
      return {
        name: 'Price',
        symbol: 'Price',
        id: 'Price',
        data: this.params.data,
        labels: this.params.label,
        color: constants.chartAxisColors.primary,
        days: this.days,
      }
    },
    volumeParams() {
      return {
        symbol: 'Volume',
        id: 'Volume',
        data: this.params.volume,
        labels: this.params.label,
        color: constants.chartAxisColors.secondary_3,
        days: this.days,
      }
    },
    marketCapParams() {
      return {
        symbol: 'Market',
        id: 'Market',
        data: this.params.marketCap,
        labels: this.params.label,
        color: constants.chartAxisColors.secondary_1,
        days: this.days,
      }
    },
    activityParams() {
      return {
        symbol: 'Activity',
        id: 'Activity',
        data: this.activity.data,
        labels: this.activity.label,
        color: constants.chartAxisColors.red,
        days: this.days,
      }
    },
    async onFetchHistory() {
      const payload = {
        id: this.$route.params.id,
        sort: 'asc',
        currency: this.selected_currency,
        days: this.days,
      }

      this.chart_loading = true
      await this.CURRENCY_HISTORY_REQUEST(payload)
      await this.CURRENCY_ACTIVITY_REQUEST(payload)

      this.onPaginate()
      this.params = this.$store.get('currency/getData')

      this.changeChartData()
      this.chart_loading = false
    },
    async onFetchCurrency() {
      this.loading = true

      const payload = {
        id: this.$route.params.id,
        sort: 'asc',
        currency: this.selected_currency,
        days: this.days,
      }

      await this.CURRENCY_REQUEST(payload)
      this.loading = false
    },
    onLegendChange(value, index) {
      this.simpleDataset = []
      if (this.selected_legends && this.selected_legends.length > 0) {
        _.forEach(this.selected_legends, (o) => {
          if (o === 'Price') {
            this.simpleDataset.push(this.priceParams())
          } else if (o === 'Volume') {
            this.simpleDataset.push(this.volumeParams())
          } else if (o === 'Market Cap') {
            this.simpleDataset.push(this.marketCapParams())
          } else if (o === 'Activity') {
            this.simpleDataset.push(this.activityParams())
          } else {
            if (!(this.simpleDataset?.length > 0)) {
              this.simpleDataset.push(this.priceParams())
              this.simpleDataset.push(this.volumeParams())
              this.simpleDataset.push(this.marketCapParams())
              this.simpleDataset.push(this.activityParams())
            }
          }
        })
      } else {
        this.simpleDataset.push(this.priceParams())
      }
    },
    onPreviousPage() {
      if (this.page <= 1) {
        this.page = 1
        this.isPrevPaginateDisabled = true
      } else {
        this.isNextPaginateDisabled = false
        this.page -= 1
      }

      this.onPaginate()
    },
    onNextPage() {
      this.page += 1

      if (this.page === this.numPages()) {
        this.isNextPaginateDisabled = true
      } else {
        this.isPrevPaginateDisabled = false
      }
      this.onPaginate()
    },
    onChangeRowsPerPage(row) {
      this.rowsPerPage = row
      this.page = 1
      this.isNextPaginateDisabled = false
      this.onPaginate()
    },
    numPages() {
      return Math.ceil(this.historyAll?.length / this.rowsPerPage)
    },
    onPaginate() {
      if (this.page < 1) {
        this.page = 1
      }

      this.history = []
      for (let i = (this.page - 1) * this.rowsPerPage; i < (this.page * this.rowsPerPage) && i < this.historyAll.length; i++) {
        this.history.push(this.historyAll[i])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.detail {
  margin-bottom: 0;
}

.currency-logo {
  height: 22px;
  position: relative;
  top: 2px;
}

.adjust-center {
  > * {
    vertical-align: inherit;
  }
}

.custom-legend {
  width: 40px;
  height: 14px;
}
</style>
