<template>
  <v-dialog
    v-model="commonDialog"
    width="500"
  >
    <v-card class="card_bg">
      <v-card-text class="pt-6">
        <h2>{{ headerText }}</h2>
        <v-divider class="my-3"/>
        <p>{{ bodyText }}</p>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-center py-3">
        <v-btn class="primary uncapitalize" block @click="redirectToLogin()">
          {{ $t('Login') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    'dialog': { type: Boolean, default: () => false  },
    'headerText': { type: String, default: () => ''  },
    'bodyText': { type: String, default: () => ''  },
  },
  computed: {
    commonDialog: {
      get: function () {
        return this.dialog ? this.dialog : false
      },
      set: function (value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.push({ path: '/account' })
    },
  },
}
</script>
