<template>
  <v-snackbar
    v-model="show"
    :timeout="5000"
    :value="true"
    top
    :color="color"
    right
    text
  >
    {{ error }}
    <v-icon
      class="snackbar-icon"
      small
      icon
      @click="show = false"
    >mdi-close</v-icon>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    'snackbar': { type: Boolean, default: () => false  },
    'color': { type: String, default: () => 'success'  },
    'error': { type: String, default: () => ''  },
  },
  computed: {
    show: {
      get: function () {
        return this.snackbar ? this.snackbar : false
      },
      set: function (value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
}
</script>
