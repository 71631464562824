<template>
  <v-dialog
    v-model="rateCoinDialog"
    width="500"
    class="ma-1 ma-sm-4"
  >
    <v-card class="card_bg">
      <v-card-text class="pa-2 pa-md-6 pb-md-3">
        <h2>{{ $t('RateCoin', [currency.name]) }}</h2>
        <v-divider class="my-3"/>
        <v-row v-for="(val, key) in rateGroup" :key="key" dense>
          <v-col cols="4">
            {{ key === 'rating' ? $t('userRating') : $t(key) }}
          </v-col>
          <v-col class="text-right">
            <v-icon
              v-for="index in 5"
              :key="index"
              :class="`
                no-hover-bg
                no-bg
                ${ val + 1 <= index ? 'icon-star-outline': 'icon-star accent--text' }
              `"
              @click="onRate(key, index)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mx-2 mx-md-6" />
      <v-card-actions class="d-block px-2 py-4 pa-md-6">
        <v-row v-if="helper.utcToDate(new Date(rateDate)) !== helper.utcToDate(new Date())">
          <v-col>
            <v-btn
              :disabled="canUserRate()"
              block
              class="primary uncapitalize"
              @click="$emit('click', rateGroup)"
            >
              {{ $t('Submit') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="helper.utcToDate(new Date(rateDate)) === helper.utcToDate(new Date())" dense>
          <v-col>
            <p class="mb-0 text-center">
              {{ $t('RateWarning') }}
            </p>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get } from 'vuex-pathify'
import helper from '@/utils/helper'

export default {
  props: {
    'dialog': { type: Boolean, default: () => false  },
    'currency': { type: Object, default: () => {}  },
  },
  data() {
    return {
      helper: helper,
      rateGroup: {},
      rateDate: '',
      hasNotRated: true,
    }
  },
  computed: {
    rateCoinDialog: {
      get: function () {
        return this.dialog ? this.dialog : false
      },
      set: function (value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  watch: {
    'currency': {
      handler: function (data) {
        this.updateRateGroup()
      },
      deep: true,
    },
  },
  methods: {
    canUserRate() {
      let flag = false

      for (const key of Object.keys(this.rateGroup)) {
        if (this.rateGroup[key] === 0) {
          flag = true
          break
        }
      }

      return flag
    },
    onRate(key, index) {
      this.hasNotRated = false
      this.$set(this.rateGroup, key, index)
    },
    updateRateGroup() {
      for (const key of Object.keys(this.currency)) {
        if (key.includes('bur_create_date'))
          this.rateDate = this.currency[key]

        if (key.includes('rating'))
          this.$set(this.rateGroup, key, this.currency[key])
      }
    },
  },
}
</script>
